/* .pedigree-page {
  font-family: Roboto, "Source Sans Pro", Helvetica, Arial, sans-serif !important;
} */

.position-relative {
  position: relative;
}

.overlay-image {
  position: absolute;
  left: 30px;
}

.pedigree-card-2 {
  border: 3px solid;
  width: 29%;
  margin-right: 10px;
  margin-top: -15px;
  margin-left: -15px;
  max-width: 120px;
  display: block;
}

.break-all {
  word-break: break-all;
}

.circular-icon {
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular-icon-small {
  /* repetitive code, extend not working */
  display: inline-block;
  border-radius: 50%;
  padding: 0.2em 0.2em;
  font-size: 18px;
  box-shadow: 0px 0px 2px #888;
}

.circular-icon-small:hover {
  cursor: pointer;
  color: black;
  background-color: white;
}

.zoom-icon {
  /* repetitive code, extend not working */
  display: inline-block;
  border-radius: 50%;
  padding: 0.5em 0.5em;
  font-size: 18px;
  box-shadow: 0px 0px 2px #888;
  color: #525252;
  background-color: white;
}

.zoom-icon:hover {
  cursor: pointer;
  color: white;
  background-color: #525252;
}

.zoom-text {
  /* repetitive code, extend not working */
  display: inline-block;
  border-radius: 100px;
  padding: 0.5em 0.5em;
  box-shadow: 0px 0px 2px #888;
  color: #525252;
  background-color: white;
  margin-left: 10px;
}

.zoom-text:hover {
  cursor: pointer;
  color: white;
  background-color: #525252;
}

.options-icon {
  display: inline-block;
  padding: 0.5em 0.6em;
  font-size: 22px;
}

.options-icon:hover {
  cursor: pointer;
  color: #9b9c9d;
}

.options-icon-small {
  display: inline-block;
  padding: 0.2em 0.4em;
  font-size: 22px;
}

.options-icon-small:hover {
  cursor: pointer;
  color: black;
}

.widget-user-2 .circular-icon {
  box-shadow: 0px 0px 2px #888;
}

.pedigree-v2-image.widget-user-image {
  margin-left: -55px;
  z-index: 10;
}

.pedigree-v2-image.widget-user-image > img {
  width: 110px;
}

.pedigree-v2.widget-user-2 .widget-user-desc {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pedigree-v2.widget-user-2 .widget-user-username {
  font-size: 18px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pedigree-v2.widget-user .widget-user-desc {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.pedigree-v2.widget-user .widget-user-username {
  font-size: 28px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.bg-maroon-v1 {
  background-color: #d81a61;
  color: white;
}
.color-maroon-v1 {
  color: #d81a61;
  background-color: white;
}
.header-maroon-v1 {
  border-top: 5px solid #d81a61 !important;
}
.border-maroon-v1 {
  border-color: #d81a61 !important;
  outline-color: #d81a61 !important;
}

.bg-aqua-v1 {
  background-color: #0092bb;
  color: white;
}
.color-aqua-v1 {
  color: #0092bb;
  background-color: white;
}
.header-aqua-v1 {
  border-top: 5px solid #0092bb !important;
}
.border-aqua-v1 {
  border-color: #0092bb !important;
  outline-color: #0092bb !important;
}

.bg-maroon-v2 {
  background-color: #f9a9c7;
}
.color-maroon-v2 {
  color: #f9a9c7;
  background-color: white;
}
.header-maroon-v2 {
  border-top: 5px solid #f9a9c7 !important;
}
.border-maroon-v2 {
  border-color: #f9a9c7 !important;
  outline-color: #f9a9c7 !important;
}
.bg-aqua-v2 {
  background-color: #4a759c;
}
.color-aqua-v2 {
  color: #4a759c;
  background-color: white;
}
.header-aqua-v2 {
  border-top: 5px solid #4a759c !important;
}
.border-aqua-v2 {
  border-color: #4a759c !important;
  outline-color: #4a759c !important;
}

.bg-maroon-v3 {
  background-color: #ffdbe9;
}
.color-maroon-v3 {
  color: #ffdbe9;
  background-color: white;
}
.header-maroon-v3 {
  border-top: 5px solid #ffdbe9 !important;
}
.border-maroon-v3 {
  border-color: #ffdbe9 !important;
  outline-color: #ffdbe9 !important;
}
.bg-aqua-v3 {
  background-color: #86d7ee;
}
.color-aqua-v3 {
  color: #86d7ee;
  background-color: white;
}
.header-aqua-v3 {
  border-top: 5px solid #86d7ee !important;
}
.border-aqua-v3 {
  border-color: #86d7ee !important;
  outline-color: #86d7ee !important;
}

.bg-maroon-v4 {
  background-color: #cb5b28;
  color: white;
}
.color-maroon-v4 {
  color: #cb5b28;
  background-color: white;
}
.header-maroon-v4 {
  border-top: 5px solid #cb5b28 !important;
}
.border-maroon-v4 {
  border-color: #cb5b28 !important;
  outline-color: #cb5b28 !important;
}
.bg-aqua-v4 {
  background-color: #03985d;
  color: white;
}
.color-aqua-v4 {
  color: #03985d;
  background-color: white;
}
.header-aqua-v4 {
  border-top: 5px solid #03985d !important;
}
.border-aqua-v4 {
  border-color: #03985d !important;
  outline-color: #03985d !important;
}

.color-gray {
  color: #a7a7a7;
}

.color-dark-gray {
  color: #5b5b5b;
}

.color-gray-white {
  color: #9b9c9d;
}

.color-white-gray {
  color: white;
  background-color: #9b9c9d;
}

.pedigree-expand-toggle[aria-expanded="true"] .fa-chevron-down {
  display: none;
}

.pedigree-expand-toggle[aria-expanded="false"] .fa-chevron-up {
  display: none;
}

.bg-pedigree-gray {
  background-color: #eeeeee;
}

.borderless {
  margin-bottom: 0 !important;
}

.borderless td,
.borderless th {
  border: none !important;
}

.pedigree-toggle-label {
  margin-right: 20px;
}
.pedigree-modal-title {
  font-weight: 600;
}
.pedigree-color-scheme-selection {
  border-top: 1px #e9e2e2 solid;
  margin-top: 10px;
  padding-top: 10px;
}
.pedigree-circle-options {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  margin-right: 5px;
  margin-left: 5px;
}
.buck-one {
  background-color: #0092bb;
}
.buck-two {
  background-color: #4a759c;
}
.buck-three {
  background-color: #86d7ee;
}
.buck-four {
  background-color: #03985d;
}
.doe-one {
  background-color: #d81a61;
}
.doe-two {
  background-color: #f9a9c7;
}
.doe-three {
  background-color: #ffdbe9;
}
.doe-four {
  background-color: #cb5b28;
}

.pedigree-row-paddings-compensation {
  padding: 0 20px;
}

.bg-emerald {
  background-color: #03985d;
}
.bg-dark-grey {
  background-color: #222d32;
}
.bg-light-grey {
  background-color: #404b50;
}
.color-white {
  color: white;
}
.border-bottom-none {
  border-bottom: none;
}
.pedigree-close-button {
  display: inline-block;
  border-radius: 50%;
  padding: 0.2em 0.3em;
  background-color: white;
}
.pedigree-nav {
  border-top: none !important;
  color: #97a1a6 !important;
}
.pedigree-nav:hover {
  color: #222d32 !important;
  border-bottom: 5px #f7f7f7 solid;
}
.nav-pills > li.active > .pedigree-nav {
  background-color: transparent !important;
  border-bottom: 5px #03985d solid;
  color: white !important;
}
.border-options-one {
  height: 60px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border: 2px #222d32 solid;
  border-top: 10px #222d32 solid;
}
.border-options-two {
  height: 60px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border: 3px #222d32 solid;
  outline: 2px #222d32 solid;
  outline-offset: -8px;
}
.border-options-three {
  height: 60px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border: 2px #222d32 dashed;
  outline: 3px #222d32 dashed;
  outline-offset: -8px;
}
.border-options-four {
  height: 60px;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  border-top: 10px #222d32 solid;
  filter: drop-shadow(0 0 10px #d2d2d2);
}
.pedigree-footer {
  border-top: 1px #c5c5c5 solid;
}
.pedigree-settings-label {
  font-size: 18px;
}
.pedigree-menu-icon {
  font-size: 16px;
}

.header-dot {
  height: 8px;
  width: 8px;
  margin-left: 10px;
  margin-right: 20px;
  background-color: #02a05b;
  border-radius: 50%;
  display: inline-block;
}

.pedigree-menu {
  border-radius: 100px;
  background-color: #eeeeee !important;
  border-color: transparent;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 0 3px;
}

.pedigree-menu-neutral {
  border-radius: 100px;
  border-color: transparent;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 0 3px;
}

.pedigree-menu-neutral:hover {
  color: var(--dark-grey);
}

.pedigree .dropdown-menu > li > a {
  font-size: 14px !important;
}

.btn-pedigree {
  border-radius: 100px;
  padding-left: 35px;
  padding-right: 35px;
}
.pedigree-menu-label {
  margin-left: 5px;
}
.pedigree-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}

.pedigree-map {
  padding: 10px;
}
.node {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 400px;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  border-radius: 0px;
  text-align: left;
  transition: all 0.2s;
  direction: ltr;
  cursor: auto;
  white-space: normal;
}
.node:hover {
  box-shadow: none;
}
.children:before {
  border-top: 3px solid #ccc;
}
.children__item:before {
  border-top: 3px solid #ccc;
}
.children__item:first-child:before {
  border-left: 3px solid #ccc;
}
.children__item:last-child:before {
  border-left: 3px solid #ccc;
  border-bottom: 3px solid #ccc;
}
.children__item:first-child:last-child:before {
  border-top: 3px solid #ccc;
}

#pedigree-box {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.pedigree-expand {
  margin-left: 5px;
  margin-right: 10px;
}

.pedigree-expand-label {
  margin-right: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

.border-v1.widget-user > .widget-user-header {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.border-v4.widget-user > .widget-user-header {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.widget-user .widget-user-header-remove-padding {
  height: 80px !important;
}

.widget-user-2 .widget-user-desc-remove-padding, .widget-user-2 .widget-user-username-remove-padding{
  margin-left: 0 !important;
}

.widget-user .box-footer-remove-padding{
  padding-top: 0 !important;
}

.pedigree-v2 {
  z-index: 999;
}

.border-v1 {
  background-color: white;
  border-radius: 6px;
  border: 2px solid;
}

.border-v2 {
  padding: 8px;
  background-color: white;
  border: 2px solid;
  outline: 1px solid;
  outline-offset: -8px;
}

.border-v3 {
  padding: 8px;
  background-color: white;
  border-radius: 6px;
  border: 1px dashed;
  outline: 2px dashed;
  outline-offset: -8px;
}

.border-v4 {
  background-color: white;
  border-radius: 6px;
  filter: drop-shadow(0 0 10px #d2d2d2);
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

/* custom select2 UI */
[class^="select2"] {
  border-radius: 4px !important;
  font-weight: 900;
}

.generation-label {
  margin-bottom: 14px;
}

.margin-left-56 {
  margin-left: 56px;
}

.padding-left-30 {
  padding-left: 30px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-top-30 {
  padding-top: 30px !important;
}
.padding-top-50 {
  padding-top: 50px !important;
}

.pedigree-v2 .img-circle {
  background-color: white;
}

/*
* Remove borders on images
*/
.pedigree-v2 .img-circle.no-border {
  border: none !important;
}

/* PDF View */
.public-pedigree-small-font .public-pedigree-card-header {
  margin-bottom: 0px;
}
.public-pedigree-normal-font .public-pedigree-card-header {
  margin-bottom: 12px;
}
.public-pedigree-large-font .public-pedigree-card-header {
  margin-bottom: 12px;
}
.public-pedigree-small-font span {
  font-size: 12px;
}
.public-pedigree-small-font b {
  font-size: 12px;
}
.public-pedigree-small-font small {
  font-size: 10px;
}
.public-pedigree-small-font h3 {
  font-size: 18px;
  font-weight: bold;
}
.public-pedigree-small-font h4 {
  font-size: 16px;
  font-weight: bold;
}
.public-pedigree-small-font h5 {
  font-size: 14px;
  font-weight: bold;
}
.public-pedigree-normal-font span {
  font-size: 14px;
}
.public-pedigree-normal-font b {
  font-size: 14px;
}
.public-pedigree-normal-font small {
  font-size: 12px;
}
.public-pedigree-normal-font h3 {
  font-size: 20px;
  font-weight: bold;
}
.public-pedigree-normal-font h4 {
  font-size: 18px;
  font-weight: bold;
}
.public-pedigree-normal-font h5 {
  font-size: 16px;
  font-weight: bold;
}
.public-pedigree-large-font span {
  font-size: 20px;
}
.public-pedigree-large-font b {
  font-size: 20px;
}
.public-pedigree-large-font small {
  font-size: 18px;
}
.public-pedigree-large-font h3 {
  font-size: 26px;
  font-weight: bold;
}
.public-pedigree-large-font h4 {
  font-size: 24px;
  font-weight: bold;
}
.public-pedigree-large-font h5 {
  font-size: 22px;
  font-weight: bold;
}
.public-pedigree-notes {
  margin-top: 10px;
}
.circular-icon-gender {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gender-color-maroon {
  color: #d81b60;
  background-color: #fbdce7;
}
.gender-color-aqua {
  color: #00c0ef;
  background-color: #d1f1fa;
}
.public-pedigree-gender {
  position: absolute;
  top: 5px;
  right: 5px;
}
.custom-description {
  width: 400px;
}
.public-pedigree-card-header-desc {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.public-pedigree-card-header-content {
  display: inline-block;
  margin: 0px 5px;
}
.custom-widget-image-normal > img {
  width: 65px;
  height: auto;
}
.custom-widget-image-small > img {
  width: 45px;
  height: auto;
}
.public-pedigree-card-header.no-margin .custom-widget-image-small > img {
  width: 40px;
  height: auto;
}
.public-pedigree-footer-logo {
  filter: invert(23%) sepia(84%) saturate(7354%) hue-rotate(141deg)
    brightness(94%) contrast(98%);
  margin: 0px 10px;
}
.public-pedigree-footer-branding {
  margin-left: auto;
  color: #03985d;
}
.public-pedigree-footer-description {
  color: #03985d;
  white-space: pre-line;
  padding-left: 10px;
}
.public-pedigree-footer-description > a {
  color: #03985d;
}
.public-pedigree-footer a {
  color: #03985d;
}
.public-pedigree-footer a:hover {
  text-decoration: underline;
}
.public-pedigree-footer-branding > b > a {
  color: #03985d;
}
.public-pedigree-footer {
  width: 100%;
  margin-top: 10px;
  border-top: 4px solid #03985d;
  padding: 5px;
  color: #03985d;
  font-size: 18px;
}
.pedigree-signature {
  border: 0;
  border-bottom: 1px solid #000;
}
.pre-footer-description {
  font-size: 15px;
}
.public-pedigree-pre-footer {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 30px;
  margin-bottom: 15px;
}
.public-pedigree-pdf .public-pedigree-pre-footer {
  margin-top: 50px;
}

.public-pedigree-card {
  width: 400px !important;
}
.public-pdf-pedigree-card > .box {
  /* border: 2px solid #465156; */
  margin-bottom: 5px;
  padding: 5px 15px;
  white-space: normal;
}
.public-pdf-pedigree-card.node {
  width: 450px;
}
.public-pdf-pedigree-card.node.long-node {
  width: 548px;
}
/* .main-node {
      position: absolute;
    top: 660px;
    left: 550px;
} */

.public-pedigree-card > .box {
  border: 2px solid #465156;
  margin-bottom: 5px;
  white-space: normal;
}
.public-pedigree-hutch-branding {
  margin-left: auto;
  color: #03985d;
  align-self: baseline;
}
.public-pedigree-hutch-branding h4 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px;
}
.public-pedigree-hutch-branding h5 {
  margin-top: 5px;
  margin-bottom: 0px;
}
.public-pedigree-hutch-branding a {
  color: #03985d;
}
.public-pedigree-hutch-branding a:hover {
  text-decoration: underline;
}
.public-pedigree-description {
  white-space: pre-line;
  font-size: 18px;
  max-width: 500px;
}
.public-pedigree-description a {
  color: var(--primary-green);
}
.vertical-divider {
  display: inline-block;
  margin: 10px 25px;
  border-left: 4px solid #03985d;
  height: 150px;
}
.public-pedigree-header {
  padding: 10px 40px 0px 40px;
}
.public-pedigree-pre-header {
  padding: 0px 40px;
  color: #03985d;
}
.public-pedigree-pdf-container {
  margin: 10px 20px;
}

.public-pedigree-pdf {
  border-width: 4px !important;
  border-color: #03985d !important;
  border-radius: 11px !important;
  margin-bottom: 0px;
  margin-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  zoom: 0.55;
}
.public-pedigree {
  border-width: 4px !important;
  border-color: #03985d !important;
  border-radius: 11px !important;
  margin: 10px 10px 0px 10px;
  zoom: 0.9;
}
.public-pedigree-icon {
  width: 65px;
  height: 65px;
  padding-top: 12px;
  background-color: #03985d !important;
  border: solid 7px #ffffff;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: -15px;
}

.public-pedigree-content-pdf {
  margin-left: 0px !important;
  margin-right: 0px !important;
  zoom: 0.81;
}
/* 
.public-pedigree-content-pdf .children:before {
  width: 45px;
}
.public-pedigree-content-pdf .children__item:before {
  width: 45px;
}
.public-pedigree-content-pdf .children__item:first-child:before {
  left: 31px;
}
.public-pedigree-content-pdf .children__item:last-child:before {
  left: 31px;
} */

.public-pedigree-content {
  padding: 20px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.public-pedigree-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.public-pedigree-content.row-horizon.pedigree {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/**
* Public view updates
**/
.public-pedigree-pre-header-icon {
  display: inline;
}
.public-pedigree-pre-header-branding {
  display: inline;
  font-weight: bold;
  font-size: 45px;
  padding-bottom: 10px;
}
.public-pedigree-pre-header-description {
  display: inline;
  font-weight: 550;
  padding-left: 10px;
}
.public-pedigree-button {
  margin-left: auto;
  border-radius: 100px;
  color: #fff;
  background-color: #03985d !important;
  border-color: transparent;
  font-weight: 550;
  padding: 10px 25px;
}
.public-pedigree-button:hover {
  color: #046740;
}
.public-pedigree-actions {
  margin: 10px 0;
  margin-left: auto;
  color: #03985d;
}
.public-pedigree-button-icon {
  padding-left: 15px;
}

.pedigree-pdf-field-content:not(.pedigree-pdf-color-field) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pedigree-pdf-color-field {
  padding-left: 0px;
  padding-right: 5px;
  line-break: anywhere;
  max-height: 30px;
}

/* generic styling */
.inline-block {
  display: inline-block;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-550 {
  font-weight: 550;
}
.font-size-16 {
  font-size: 16px;
}

.pedigree-field-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.public-pedigree-image {
  object-fit: contain;
  width: 100%;
  max-width: 250px;
}

.public-pedigree-logo {
  justify-content: center;
}

.pedigree .pedigree-menu .fa {
  font-size: 16px;
}

@media (max-width: 767px) {
  .pedigree-row-paddings-componsation {
    padding: 0;
  }

  .public-pedigree-pre-header {
    padding: 0px 10px;
  }
}

@media (max-width: 600px) {
  .wm-100 {
    width: 100% !important;
  }
}

/* PDF Pedigree */
.public-pedigree-pdf-container .public-pedigree-header {
  max-height: 160px;
}
.public-pedigree-pdf-container .vertical-divider {
  height: 130px;
}
.public-pedigree-pdf-container .public-pedigree-pre-footer {
  position: absolute;
  bottom: 110px;
  width: 350px;
  font-size: 18px;
  font-weight: 600;
}
.public-pedigree-pdf-container .public-pedigree-content-pdf {
  min-height: 1350px;
}
.public-pedigree-pdf-container .public-pedigree-description {
  max-height: 130px;
}

.pedigree-settings-toggle > div {
  white-space: nowrap;
}

.public-pedigree-card-body .col-sm-2 > b {
  white-space: nowrap;
}