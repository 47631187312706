body {
  font-family: Roboto, "Source Sans Pro", Helvetica, Arial, sans-serif !important;
  --primary-white: #fff;
  --secondary-white: #fafafa;
  --primary-green: #03985d;
  --secondary-green: #02a05b;
  --success-green: #12cd66;
  --light-green: #eef8eb;
  --dark-grey: #1e2d33;
  --faded-grey: #3e4945;
  --light-grey: #9ba4b0;
  --lighter-grey: #e9edf5;
  --secondary-grey: #8fa2b3;
  --table-light-grey: #f3f3f3;
  --border-grey: #8a8a8a;
  --border-light-grey: #e1e1e1;
  --footer-grey: #dae1ed;
  --gunmetal: #222d32;
  --primary: #0ba2b7;
  --success: #12cd66;
  --danger: #dc3545;
  --warning: #fec72d;
  --dropzone-bg: #fafcfd;
  --dropzone-font: #8f9bb3;
  --link: #0676ed;
  --generic-bg: #eeeeee;
  --primary-blue: #009bc2;
  --primary-red: #f72728;
  --primary-teal: #00ddc5;
  --primary-crimson: #da0f5c;
  --primary-purple: #964eff;
  --primary-yellow: #ff8900;
}

/* START SIDEBAR CSS */

.nav-label {
  padding: 15px 0px !important;
}

.nav-label > span {
  padding: 12px 5px 12px 15px;
  color: var(--light-grey);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
}

.sidebar-menu > li > a {
  color: var(--primary-white) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
}

.skin-green .sidebar-menu > li.active:not(.treeview) > a,
.skin-green .sidebar-menu > li > a.active,
.skin-green .sidebar-menu > li > a.v-link-active {
  background-color: var(--secondary-green) !important;
  border-radius: 5px;
}

.skin-green .sidebar-menu > li.active > a {
  border-left-color: transparent;
}

.sidebar-mini.sidebar-collapse .main-sidebar .sidebar-menu {
  padding: 0px;
}
.sidebar-mini.sidebar-collapse .main-sidebar .nav-label > span,
.sidebar-mini.sidebar-collapse .main-sidebar .copyright-content {
  display: none;
}

.sidebar-mini.sidebar-collapse .main-sidebar .sidebar-menu > li > a {
  border-left: none;
}

.sidebar-menu {
  padding: 0px 10px;
  background: var(--gunmetal);
}

.sidebar-menu > li > a > .fa {
  font-size: 20px;
}

.sidebar-menu > li > a > span {
  margin-left: 10px;
}

.skin-green .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: var(--gunmetal);
}

.main-sidebar > .sidebar > .sidebar-menu {
  padding-bottom: 120px !important;
}

.copyright-content {
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid var(--light-grey);
  padding: 24px 15px;
}

.copyright-content .cc-title {
  color: var(--primary-white);
  font-size: 12px;
}

.copyright-content .cc-description {
  color: var(--light-grey);
  margin-top: 10px;
  font-size: 12px;
}

/* END SIDEBAR CSS */

/* START HEADER CSS */

.main-header .logo .logo-mini {
  padding: 0px 5px;
}

.skin-green .main-header .logo,
.skin-green .main-header .navbar {
  background-color: var(--secondary-green);
}

.main-header .sidebar-toggle:before {
  content: "";
}

.tasks-menu > a > .fa,
.settings-dropdown .fa {
  font-size: 25px;
}

.main-header .logo {
  height: 58px;
}

.settings-dropdown img {
  width: 30px;
  height: 30px;
  background-color: var(--primary-white);
  border-radius: 50%;
  border: 3px solid white;
}

.dropdown-menu {
  background-color: var(--secondary-white);
  min-width: 200px;
  border-radius: 2px !important;
}

.dropdown-menu > li > a > .fa {
  width: 15%;
}

.header.dropdown-menu > li > a > .fa,
.breeders-v2 .dropdown-menu > li > a > .fa {
  font-size: 18px;
}

.header.dropdown-menu > li > a,
.breeders-v2 .dropdown-menu > li > a {
  font-size: 16px;
}

.header.dropdown-menu > li:not(.divider) > a,
.breeders-v2 .dropdown-menu > li:not(.divider) > a {
  padding: 8px 20px;
}

.header.dropdown-menu > li:not(.divider) > a:hover,
.breeders-v2 .dropdown-menu > li:not(.divider) > a:hover,
.breeders-v2 .dropdown-menu > li:not(.divider) > a:hover > i.fa {
  background-color: var(--primary-green);
  color: var(--primary-white);
}

.main-header .navbar .breadcrumbs,
.main-header .navbar .breadcrumbs a {
  color: var(--primary-white);
  font-size: 14px;
  line-height: 56px;
}

@media (min-width: 768px) {
  .main-header .navbar .breadcrumbs {
    margin: 0px 25px;
  }

  #litter-butcher-modal .modal-dialog,
  #global-litter-butcher-modal .modal-dialog {
    width: 750px;
  }
}

.main-header .navbar .breadcrumbs a:hover {
  text-decoration: underline;
}
/* END HEADER CSS */

/* START TABLE CSS */

.btn-custom-action {
  background-color: transparent;
  border: none;
}
.btn-custom-action .fa {
  color: var(--primary-green);
  font-size: 8px;
}
.pl-1 {
  padding-left: 3px;
}
.pl-2 {
  padding-left: 6px;
}

.table-v2 {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.table-v2 thead {
  background-color: var(--table-light-grey);
  display: table-row-group;
}

.table-v2 thead th {
  font-size: 14px;
  font-weight: 900;
  padding: 1.2% 0px !important;
  vertical-align: middle !important;
  max-width: 100%;
  white-space: nowrap !important;
}

.table-v2 thead th > div {
  display: inline-block;
  padding-left: 1.5% !important;
  padding-right: 1.5% !important;
}

.table-v2 td {
  font-size: 13px;
  padding: 1.2% 0px !important;
  vertical-align: middle !important;
  white-space: normal !important;
}

.table-v2 thead th:first-child,
.table-v2 td:first-child {
  padding-left: 1.5% !important;
}

.table-v2 thead th:last-child,
.table-v2 td:last-child {
  padding-right: 1.5% !important;
}

.table-v2 tbody:before {
  content: "@";
  display: block;
  line-height: 1px;
  text-indent: -99999px;
}

.table-v2 thead th,
.table-v2 tbody td {
  border-top: 1px solid var(--table-light-grey) !important;
  border-bottom: 1px solid var(--table-light-grey) !important;
}
.table-v2 tbody .selected-edit td {
  border-top: 2px solid var(--primary-green) !important;
  border-bottom: 2px solid var(--primary-green) !important;
}
.table-v2 tbody td:first-child,
.table-v2 thead th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid var(--table-light-grey);
}
.table-v2 tbody .selected-edit td:first-child {
  border-left: 2px solid var(--primary-green) !important;
}
.table-v2 tbody td:last-child,
.table-v2 thead th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 1px solid var(--table-light-grey);
}
.table-v2 tbody .selected-edit td:last-child {
  border-right: 2px solid var(--primary-green) !important;
}

.table-v2 tbody td.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.table tfoot tr td {
  border-top: none !important;
}

/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .fa {
  position: absolute;
  padding: 7px;
  pointer-events: none;
  font-size: 20px;
}

.inner-addon .fa.fa-eye,
.inner-addon .fa.fa-eye-slash {
  pointer-events: auto;
  cursor: pointer;
}

/* align icon */
.left-addon .fa {
  left: 0px;
}
.right-addon .fa {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 32px;
}
.right-addon input {
  padding-right: 32px;
}

.search-box input {
  border: 1px solid var(--border-grey);
  border-radius: 4px;
  background-color: var(--primary-white);
}

.fa-stack:not(.fa-lg) {
  margin-left: 3px;
  color: var(--border-grey);
  width: 1em;
}

.fa-stack .fa-caret-down {
  position: absolute;
  bottom: 3px;
}

.fa-stack .fa-caret-up {
  position: absolute;
  top: 2px;
}

.btn-custom-action + ul {
  right: 0;
  left: auto;
}
/* END TABLE CSS */

/* START FORMS CSS */
input,
select,
textarea {
  border-radius: 5px !important;
}

.input-group:not(.no-curve) .form-control:first-child,
.input-group:not(.no-curve) .select2-selection {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group:not(.no-curve) .form-control {
  border-radius: 0px !important;
}

.input-group:not(.no-curve) .input-group-addon {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.help-block.error,
.val-error,
small.error {
  color: var(--danger);
}

small.error {
  font-size: 12px;
}

small.error .fa {
  font-size: 14px;
}
/* END FORMS CSS */

/* START MODALS CSS */
.modal {
  border-spacing: 0;
}
.modal-content {
  border-radius: 12px;
}
.modal-content .modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 30px;
  padding-right: 25px;
}
.modal-content .modal-body {
  background-color: var(--primary-white) !important;
  color: var(--dark-grey) !important;
  font-size: 16px;
}
.modal-content .modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.close {
  opacity: 1;
}

.close .fa-times-circle {
  font-size: 25px;
  color: var(--primary-white);
}

.modal .btn {
  margin-left: auto;
  border-radius: 100px;
  font-weight: 550;
}

.modal button.btn,
.modal .btn.a-btn {
  margin: 0px 5px;
  font-size: 12px;
  padding: 8px 20px;
}

.modal .modal-header {
  background-color: var(--gunmetal) !important;
  color: var(--primary-white);
}

.modal .modal-footer {
  background-color: var(--primary-white) !important;
  border-top: 2px solid var(--footer-grey);
}

.modal .modal-header h4 {
  font-size: 18px;
  font-weight: 700;
}

.modal-nav {
  font-size: 14px;
  font-weight: 500;
  border-top: none !important;
  color: var(--light-grey) !important;
  border-bottom: 3px var(--table-light-grey) solid;
}
.modal-nav:hover {
  color: var(--gunmetal) !important;
  border-bottom: 3px var(--primary-green) solid;
}
.nav-pills > li.active > .modal-nav {
  background-color: transparent !important;
  border-bottom: 3px var(--primary-green) solid;
  color: var(--gunmetal) !important;
}
.nav-pills > li > a {
  padding: 15px;
  white-space: nowrap;
}
/* END MODALS CSS */

/* EXTRAS */

table:not(.breeder-import-table) td > input,
table:not(.breeder-import-table) td > div > input {
  border: none !important;
}

.modal-footer .btn {
  min-width: 90px;
}

.cancel-btn {
  border: solid 2px #4c4c4c;
  color: #4c4c4c;
}

div.scrollable-table-wrapper {
  max-height: 600px;
  overflow: auto;
}

div.scrollable-table-wrapper thead tr th {
  position: sticky;
  top: 0;
  background-color: var(--table-light-grey);
  z-index: 1;
}

.breeder-category-link {
  color: var(--primary-green);
  text-decoration: underline;
}

#photos.tab-pane {
  padding-bottom: 120px;
}

.badge {
  font-size: 12px;
  font-weight: bold;
}
.badge.badge-secondary {
  background-color: var(--lighter-grey);
  color: var(--dark-grey);
  border-radius: 3px;
  padding: 3px 16px;
}

.image-remove-icon > .fa-times-circle {
  font-size: 25px;
  color: var(--danger);
  pointer-events: none;
}

.input-group.bootstrap-filestyle .form-control {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.input-group.bootstrap-filestyle .btn.btn-secondary {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background-color: #f4f4f4;
  color: var(--dark-grey);
  border-color: #ddd;
}

.field-popover.top .arrow:after {
  border-top-color: var(--dark-grey);
}
.field-popover.bottom .arrow:after {
  border-bottom-color: var(--dark-grey);
}
.field-popover.left .arrow:after {
  border-left-color: var(--dark-grey);
}
.field-popover.right .arrow:after {
  border-right-color: var(--dark-grey);
}
.field-popover {
  background-color: var(--dark-grey);
  color: var(--primary-white);
}
.field-popover .popover-content {
  font-size: 14px;
  font-weight: bold;
}

.field-description {
  font-size: 20px;
  margin-left: 5px;
}

.field-description .fa {
  color: var(--dark-grey);
}

.mt-40-i {
  margin-top: 40px !important;
}

.cursor-block {
  cursor: not-allowed;
}

.ml-10 {
  margin-left: 10px;
}

.jq-toast-wrap {
  width: 400px;
}

.jq-toast-single {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.jq-toast-single .fa {
  font-size: 20px;
}

.close-jq-toast-single {
  font-size: 18px;
  font-weight: bold;
  top: revert;
  right: 15px;
}

/* DATERANGEPICKER AND DATEPICKET TWEAK */
.daterangepicker .btn {
  margin-left: auto;
  border-radius: 100px;
  font-weight: 550;
}

.daterangepicker button.btn {
  font-size: 12px;
  padding: 8px 20px;
}

.daterangepicker button.btn.cancelBtn {
  margin-left: 11px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus {
  background-color: var(--secondary-green);
  border-color: var(--primary-green);
  color: var(--primary-white);
}

.daterangepicker td.start-date.end-date,
.datepicker table tr td,
.datepicker table tr th,
.daterangepicker td,
.daterangepicker th {
  border-radius: 50%;
}

.daterangepicker td.start-date {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.daterangepicker td.end-date {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.daterangepicker td.in-range {
  background-color: var(--light-green);
}

.dropzone-text {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}

.dropzone-text img {
  object-fit: cover;
  width: 30%;
}

.dropzone-text span {
  font-size: 11px;
  color: var(--dropzone-font);
}

.dropzone-text span > .text-link {
  cursor: pointer;
  color: var(--link);
}

.dropzone-text span > .text-primary:hover {
  text-decoration: underline;
}

.action-btn.btn {
  margin: 0px 5px;
  font-size: 12px;
  padding: 8px 20px;
  border-radius: 100px;
  font-weight: 550;
}

.broadcast-alert {
  padding: 20px;
}
.broadcast-alert .alert {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-bottom: 0px;
}

.broadcast-alert .alert-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px;
}

.broadcast-alert .alert .close {
  opacity: 1;
}

.broadcast-alert .broadcast-title {
  font-size: 20px;
  font-weight: 900;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
  border-color: #f1cccb !important;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
  border-color: #bcdab0 !important;
}

.ledger-total {
  width: 200px;
  padding: 12px 14px;
  border-radius: 4px;
  background-color: #e8e8e8;
  font-size: 20px;
  font-weight: 500;
}

.ledger-total > .ledger-total-key {
  margin-right: auto;
}

.ledger-total > .ledger-total-value {
  margin-left: auto;
}

.attachment-table-actions .btn {
  font-size: 14px !important;
  font-weight: normal !important;
  color: var(--gunmetal);
  background-color: var(--primary-white);
  border-color: #ccc;
}

.attachment-table-actions .btn .fa {
  width: 20%;
}

.w-100 {
  width: 100% !important;
}

.storage-description {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}

.color-hutch {
  color: var(--primary-green);
}

.no-attachments-text {
  font-size: 16px;
  font-weight: bold;
  color: var(--gunmetal);
  justify-content: center;
  height: 300px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.select2-dropdown {
  margin-top: 5px;
  border-top: 1px solid #d2d6de;
  box-shadow: 0px 0px 5px #afaaaa;
}

.user-login-logs {
  margin-top: 30px;
  height: 600px;
  overflow-y: auto;
}

.user-login-logs thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--table-light-grey);
}

.user-login-logs .btn {
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 14px;
}

.introjs-showElement.dropdown-menu,
.introjs-fixParent.dropdown-menu {
  display: block;
}

.color-border-light-grey {
  color: var(--border-light-grey);
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pr-0 {
  padding-right: 0;
}

.bg-beige {
  background-color: beige;
}

.bg-primary-green {
  background-color: var(--primary-green);
}

.btn-transparent {
  border: 1px solid var(--primary-white);
  background: transparent;
  color: var(--primary-white);
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
